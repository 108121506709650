<template>
    <v-container class="py-8">
        <v-card class="elevation-0" light>
            <!-- DESKTOP -->
            <v-container fluid class="d-none d-lg-block py-16">
                <v-row justify="center">
                    <v-col cols="12" lg="7" class="text-uppercase text-center font-weight-bold text-h4">
                        {{$t('price.fair')}}
                    </v-col>
                    <v-col cols="12">
                        <v-row no-gutters justify="center" align="center">
                            <v-col cols="12" lg="auto" justify="center" align="center" class="text-h6 font-weight-bold">
                                Empresa privada
                            </v-col>
                            <v-col cols="12" lg="auto" class="ml-4 mr-2">
                                <v-switch 
                                    color="black" 
                                    v-model="priceToggle"
                                    inset
                                />
                            </v-col>
                            <v-col cols="12" lg="auto" class="text-h6 font-weight-bold">
                                Instituição pública
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="auto" align="end" v-for="plan in getPlans" :key="plan.title">
                        <v-card dark class="elevation-10 rounded-xl black" height="325" width="275">
                            <v-card-subtitle class="text-center white--text text-h4">
                                {{plan.name}}
                            </v-card-subtitle>
                            <v-card-subtitle class="text-center white--text text-h5">
                                <div  v-if="plan.promocode_id != null">
                                {{calculateDiscount(plan)}}<span class="grey--text text-h6">/{{$t('globals.month')}}</span>
                                </div>
                            </v-card-subtitle>
                            <v-card-subtitle :class="plan.promocode_id == null ? 'text-center white--text text-h5 mt-n6' : 'text-center grey--text text-h5 text-decoration-line-through mt-n6'">
                                {{plan.price}}<span class="grey--text text-h6" v-if="plan.promocode_id == null">/{{$t('globals.month')}}</span>
                            </v-card-subtitle>
                                <div style="margin: auto; display: block; background-color: white; border-radius: 100px; height: 1px; width: 75%;"></div>
                                
                            <v-card-subtitle class="text-center white--text text-h6">
                                {{plan.n_workers}}
                            </v-card-subtitle>
                            <v-card-text class="text-center mt-n6">
                                {{plan.institutional_type == 'private' ? $t('globals.workers') : $t('globals.habitants_associated')}}
                            </v-card-text>
                            <v-card-actions class="justify-center mt-2">
                                <v-btn @click="usePromocode(plan, plan)" small class="font-weight-bold neonText-hover" rounded outlined>{{$t('globals.try')}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>


                    
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="auto" class="text-center text-caption">
                        {{$t('price.iva')}}
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="auto" class="text-center text-h4 font-weight-medium">
                        {{$t('price.plans_include')}} <b>{{$t('price.30_free')}}!</b>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-n6">
                    <v-col cols="12" lg="6" class="text-h6 text-center">
                        {{$t('price.no_risk')}}
                    </v-col>
                </v-row>
                <v-row justify="center" class="py-2">
                    <v-col cols="12" lg="auto">
                        <v-expansion-panels dark class="price-expansion-panel">
                            <v-expansion-panel class="rounded-xl">
                                <v-expansion-panel-header v-slot="{ open }">
                                    <v-row no-gutters class="text-h5 font-weight-medium">
                                        <v-col cols="12" v-if="!open">
                                            <v-row>
                                                <v-col cols="auto">
                                                    <div style="background-color: grey; border-radius: 100px; height: 100%; width: 5px;"></div>
                                                </v-col>
                                                <v-col cols="auto">
                                                    {{$t('price.what_included')}}
                                                </v-col>                                        
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="open"
                                            cols="12" align="center"
                                        >
                                            <v-fade-transition leave-absolute>
                                                <span v-if="open">{{$t('price.all_plans_included')}}</span>
                                            </v-fade-transition>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="absolute-expansion-panel">                                    
                                    <v-row class="text-caption mt-4 mb-0 grey--text" align="center">
                                        <v-col cols="12" lg="6" v-for="item in includedItems" :key="item.id">
                                            <v-icon dark class="mr-2">mdi-check-circle</v-icon>{{item.text}}
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" lg="12" class="text-center text-h6">
                        Tem mais de <b>1000 colaboradores</b>?
                    </v-col>
                    <v-col cols="12" lg="12" class="text-center text-h6 mt-n7">
                        Ou necessita de um plano customizado às necessidades do seu negócio?
                    </v-col>
                    <v-col cols="12" lg="6" class="text-center text-body-1">
                        A plataforma poderá ser customizada à sua empresa, desde campos persoanlizados, fluxos de trabalho à medida, a integrações APIs, e/ou ainda formação.
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" class="text-center">
                        <v-btn small class="black" @click="$emit('scroll-to', '#contacts')"  rounded outlined dark>{{$t('globals.team_speak')}}</v-btn>
                    </v-col>
                </v-row>
                
            </v-container>
            <!-- DESKTOP -->
            <!-- MOBILE -->
            <v-container fluid class="d-lg-none pt-8">
                <v-row justify="center">
                    <v-col cols="12">
                        <p class="text-uppercase font-weight-medium text-center" style="font-size: 19px;">{{$t('price.fair')}}</p>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-row no-gutters justify="space-between" align="center">
                            <div class="font-weight-bold" style="font-size: 14px;">
                                Empresa privada
                            </div>
                            <div class="ml-4">
                                <v-switch 
                                    color="black" 
                                    v-model="priceToggle"
                                    inset
                                />
                            </div>
                            <div class="font-weight-bold" style="font-size: 14px;">
                                Instituição pública
                            </div>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="auto" align="center" v-for="(plan, index) in getPlans" :key="plan.title" :class="index == selectedPlan ? 'selected-plan' : ''">
                        <v-card dark @click="selectPlan(index, plan);" :class="index == selectedPlan ? 'elevation-24 rounded-xl white plan-card' : 'elevation-2 rounded-xl black plan-card'" height="200" width="90%" style="display: flex; align-items: center; justify-content: center;">
                            <div>
                                <v-card-subtitle :class="index == selectedPlan ? 'text-center black--text font-weight-bold mb-4 pt-0' : 'text-center white--text font-weight-bold mb-4 pt-0'" style="font-size: 31px;">
                                    {{plan.name}}
                                </v-card-subtitle>
                                <v-card-subtitle :class="index == selectedPlan ? 'text-center black--text mt-n6 font-weight-bold' : 'mt-n6 text-center white--text font-weight-bold'" style="font-size: 20px;">
                                    <div  v-if="plan.promocode_id != null">
                                    {{calculateDiscount(plan)}}<span class="grey--text text-h6">/{{$t('globals.month')}}</span>
                                    </div>
                                </v-card-subtitle>
                                <v-card-subtitle :class="plan.promocode_id == null ? 'text-center white--text text-h5 mt-n6' : 'text-center grey--text text-h5 text-decoration-line-through mt-n6'">
                                    {{plan.price}}<span class="grey--text text-h6" v-if="plan.promocode_id == null">/{{$t('globals.month')}}</span>
                                </v-card-subtitle>                    
                                <div class="mt-n2" :style="index == selectedPlan ? 'margin: auto; display: block; background-color: black; border-radius: 100px; height: 1px;' : 'margin: auto; display: block; background-color: white; border-radius: 100px; height: 1px;'"></div>
                                <v-card-subtitle :class="index == selectedPlan ? 'mt-n2 text-center black--text text-body-1 font-weight-bold' : 'mt-n2 text-center white--text text-body-1 font-weight-bold'" style="font-size: 21px;">
                                    {{plan.n_workers}}
                                </v-card-subtitle>
                                <v-card-text :class="index == selectedPlan ? 'text-center black--text mt-n6 pb-0 pt-1' : 'text-center mt-n6 pb-0 pt-1'" style="font-size: 15px;">
                                    {{plan.institutional_type == 'private' ? $t('globals.workers') : $t('globals.habitants_associated')}}
                                </v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="4" class="px-4 py-0 mt-4 mb-8 text-center" style="font-size: 13px;">
                        {{$t('price.iva')}}
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="auto" class="px-0 py-0 mb-4 text-center font-weight-regular" style="font-size: 17px;">
                        {{$t('price.plans_include')}} <br/><span class="font-weight-bold">{{$t('price.30_free')}}!</span>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="6" class="px-0 py-0 text-center" style="font-size: 13px;">
                        {{$t('price.no_risk')}}
                    </v-col>
                </v-row>
                <v-expansion-panels dark class="mb-16 mt-12">
                    <v-expansion-panel class="rounded-xl">
                        <v-expansion-panel-header v-slot="{ open }">
                            <v-row no-gutters class="text-h7 font-weight-medium">
                                <v-col cols="12" v-if="!open">
                                    <v-row>
                                        <v-col cols="auto">
                                            <div style="background-color: grey; border-radius: 100px; height: 100%; width: 5px;"></div>
                                        </v-col>
                                        <v-col cols="auto">
                                            {{$t('price.what_included')}}
                                        </v-col>                                        
                                    </v-row>
                                </v-col>
                                <v-col v-if="open"
                                    cols="12" align="center"
                                >
                                    <v-fade-transition leave-absolute>
                                        <span v-if="open">{{$t('price.all_plans_included')}}</span>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-4">                                
                            <v-row class="mt-2 mb-4 white--text" align="center" style="font-size: 14px;">
                                <v-col cols="12" v-for="item in includedItems" :key="item.id">
                                    <v-icon dark class="mr-2">mdi-check-circle</v-icon>{{item.text}}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                </v-expansion-panels>

                <v-row justify="center">
                    <v-col cols="12" lg="12" class="text-center font-weight-regular" style="font-size:15px;">
                        {{$t('price.have_more')}} <span class="font-weight-bold">{{$t('price.thousand_workers')}}</span>?
                    </v-col>
                    <v-col cols="12" lg="12" class="text-center mt-n6 font-weight-regular" style="font-size:15px;">
                        {{$t('price.need_plan')}}
                    </v-col>
                    <v-col cols="12" lg="6" class="text-center font-weight-regular" style="font-size:14px;">
                        {{$t('price.platform')}}
                    </v-col>
                </v-row>
            </v-container>
            <!-- MOBILE -->
        </v-card>
    </v-container>
</template>
<script>
import SubscriptionsTypes from "@/api/SubscriptionsTypes.js"

export default{
    name: 'Price',
    data(){
        return{
            priceToggle: false,
            selectedPlan: -1,
            subscriptions: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    computed:{
        getPlans(){
            let data = []
            if(this.priceToggle == true){
                this.subscriptions.forEach(element => {
                    if(element.institutional_type == 'public')
                        if(element.price > 0)
                            data.push(element)
                });
            }else{
                    
                this.subscriptions.forEach(element => {
                    if(element.institutional_type == 'private')
                        if(element.price > 0)
                            data.push(element)
                });
            }


            return data
        },
        includedItems(){
            return this.$t('price.included_items');
        }
    },
    methods:{
        usePromocode(item, plan){
            console.log(plan)
            let link = process.env.VUE_APP_BACKOFFICE

            if(item.promocode_id != null)
                link = process.env.VUE_APP_BACKOFFICE + 'promocode/' + item.promocode.promocode_url + '/' + plan.id + '/register'
            else
                link = process.env.VUE_APP_BACKOFFICE + 'promocode/none/' + plan.id + '/register'

            window.open(link, '_blank')
        },
        calculateDiscount(item){
            let discount = item.promocode.discount / 100


            let final_price = parseFloat(item.price) * parseFloat(discount)
   

            return parseFloat(item.price) - parseFloat(final_price).toFixed(2)
        },
        fillBaseData(){
            SubscriptionsTypes.list().then((resp)    =>  {
                this.subscriptions = resp.data
            })
        },
        selectPlan(index, plan) {
            if(this.selectedPlan == index) this.selectedPlan = -1;
            else this.selectedPlan = index;

            this.usePromocode(plan, plan)
        },
        refreshScrollTimeout(){
            const that = this;
            setTimeout(function(){
                that.$emit('refreshScroll');
            }, 500);
        },
    }
}
</script>
<style>
@media screen and (min-width: 913px){
    .price-expansion-panel{
        width: 873px !important;
    }
}
@media screen and (max-width: 912px){
    .price-expansion-panel{
        width: 100% !important;
    }
}
@media screen and (max-width: 1263px){
    .selected-plan .plan-card{
        min-width: 100%;
    }
    .plan-card{
        transition: all 0.3s ease-in-out;
        min-width: unset;
    }
}
</style>