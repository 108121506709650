import { extend } from 'vee-validate';
import { required, email, numeric, min_value, max_value } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'

extend('required', {
    ...required,
    message: '{_field_} é obrigatório',
})

extend('between', {
  validate(value) {
    return value >= 0 && value <= 100
  },
  message: '{_field_} tem de estar entre 0 e 100',
})

extend('email', {
    ...email,
    message: '{_field_} deve ser um email válido',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} deve ser numérico',
})

extend('min_value', {
    ...min_value,
    message: '{_field_} deve ser maior ou igual a {min}',
})

extend('max_value', {
    ...max_value,
    message: '{_field_} deve ser menout ou igual a {max}',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Confirmação de password diferente'
});

extend('no_accent', {
  validate(value){
    for (const char of value) {
      if (!(char >= "a" && char <= "z"))
        if(char != '_' && char != '-')
          return false;
          
    }
    return true
  },
  message: "Use somente letras mínusculas, underscores e/ou barras horizontais"
})

extend('remove_spec_char', {
  validate(value){
    var specialChars = "<>@!#$%^&*()+[]{}?:;|'\"\\,./~`=";
    let v = true
    for(let i = 0; i < specialChars.length;i++){
      if(value.indexOf(specialChars[i]) > -1){
          v = false
       }
    }
    
    return v
  },
  message: "Não são permitidos carateres especiais. Use somente letras mínusculas, underscore e/ou barra horizontal"
})

extend('small_letter', {
  validate(value){
    let v = true
    for(let i = 0; i < value.length;i++){
      if(value[i].length === 1 && value[i].match(/[a-z]/i))
        if(value.indexOf(value[i]) > -1){
            if(value[i].toUpperCase() == value[i])
              v = false
        }
    }
    
    return v
  },
  message: "Use somente letras minusculas"
})

extend("date_min", {
  params: ['target'],
    validate(value, { target }) {

        if (value.length == 0) {
            return true;
        }

        value = value.split("/");

        target = target.split("/");

        value = value[2] + "-" + value[1] + "-" + value[0];

        target = target[2] + "-" + target[1] + "-" + target[0];

        return dayjs(value).isSame(dayjs(target)) || dayjs(value).isAfter(dayjs(target));
  },
  message: "A data tem de ser maior ou igual a {target}!"
});

extend('string_size_15', {
  validate(value){
    if(value.length >= 9)
      return true
    return false
  },
  message: "Por favor insira pelo menos 15 carateres"
})