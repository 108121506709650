<template>
  <v-theme-provider root>
    <v-app>
      <v-app-bar class="d-none d-lg-block black" elevation="4">
        <v-toolbar-title class="ml-16">
          <v-img class="logo-img" style="cursor: pointer;" @click="menuClick('')" src="@/assets/icons/icone1.png"
            width="200px" contain />
        </v-toolbar-title>
        <v-spacer></v-spacer>


        <span class="styling-cursor mr-16 font-weight-bold" @click="menuClick('product')">{{ $t('menu.product')
          }}</span>
        <span class="styling-cursor mr-16 font-weight-bold" @click="menuClick('price')">{{ $t('menu.price') }}</span>
        <span class="styling-cursor mr-16 font-weight-bold" @click="menuClick('faqs')">{{ $t('menu.faqs') }}</span>
        <span class="styling-cursor mr-16 font-weight-bold" @click="menuClick('contacts')">{{ $t('menu.contacts')
          }}</span>
        <v-btn class="styling-cursor mr-16 font-weight-bold" @click="startSession" rounded outlined>
          {{ $t('menu.session') }}
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark text class="ml-n8" v-bind="attrs" v-on="on">
              <v-icon>mdi-web</v-icon>
              {{ $i18n.locale }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index" @click="changeLanguage(item)"
              style="cursor: pointer;">
              <v-list-item-title class="text-uppercase">{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-app-bar>

      <v-app-bar class="d-lg-none black" elevation="4" fixed>
        <v-row>
          <v-col cols="12" align="end">
            <span @click="hamburguerMenu = true" class="font-weight-bold">
              <v-icon large>mdi-menu</v-icon>
            </span>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-dialog v-if="hamburguerMenu" v-model="hamburguerMenu" fullscreen>
        <v-card class="elevation-0 white black--text">
          <v-toolbar class="white elevation-0">
            <v-toolbar-title>
              <v-img style="cursor: pointer;" @click="menuClick('home')" src="@/assets/icons/logo_pequeno_final.png"
                width="20vh" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="hamburguerMenu = false" large color="black">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text class="black--text mt-16 font-weight-bold text-h4">
            <v-list class="transparent">
              <v-list-item class="text-h4 font-weight-bold black--text mb-8 mt-n8">
                <v-btn color="black" class="mt-6 styling-cursor mr-16 font-weight-bold" @click="startSession" rounded
                  outlined>
                  {{ $t('menu.session') }}
                </v-btn>
              </v-list-item>
              <v-list-item class="text-h4 font-weight-bold black--text"
                @click="menuClick('product'); hamburguerMenu = false;">{{ $t('menu.product') }}</v-list-item>
              <v-list-item class="text-h4 font-weight-bold black--text"
                @click="menuClick('price'); hamburguerMenu = false;">{{ $t('menu.price') }}</v-list-item>
              <v-list-item class="text-h4 font-weight-bold black--text"
                @click="menuClick('faqs'); hamburguerMenu = false;">{{
            $t('menu.faqs') }}</v-list-item>
              <v-list-item class="text-h4 font-weight-bold black--text"
                @click="menuClick('contacts'); hamburguerMenu = false;">{{ $t('menu.contacts') }}</v-list-item>
            </v-list>
            <v-list class="transparent mt-4">
              <v-list-item style="cursor: pointer;" class="mt-n4 text-h6 font-weight-medium black--text"
                @click="menuClick('privacy'); hamburguerMenu = false;">{{ $t('footer.privacy_and_policy')
                }}</v-list-item>
              <v-list-item style="cursor: pointer;" class="mt-n4 text-h6 font-weight-medium black--text"
                @click="menuClick('terms'); hamburguerMenu = false;">{{ $t('footer.terms') }}</v-list-item>
            </v-list>
            <v-list class="transparent">
              <v-list-item class="d-flex">
                <v-btn v-for="(item, index) in items" :key="index"
                  :class="$i18n.locale == item ? 'mr-4 rounded-xl px-6 btn-lang-selected' : 'mr-4 rounded-xl px-6 btn-lang'"
                  @click="changeLanguage(item)" style="cursor: pointer;">
                  {{ item == 'pt' ? 'Português' : 'English'}}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-main light>
        <router-view :section="section"></router-view>
      </v-main>

    </v-app>

  </v-theme-provider>
</template>
<script>

export default {
  components: {

  },
  data() {
    return {
      items: ['pt'],
      hamburguerMenu: false,
      section: null
    }
  },
  mounted() {

  },
  methods: {
    startSession() {
      let link = process.env.VUE_APP_BACKOFFICE

      window.open(link, '_blank')
    },
    menuClick(tag) {

      if (tag == '')
        this.section = null
      else if (tag == 'privacy') this.$router.push('/' + this.$i18n.locale + '/' + this.$t('footer.link_politica'))
      else if (tag == 'terms') this.$router.push('/' + this.$i18n.locale + '/' + this.$t('footer.link_termos'))
      else {
        this.section = "#" + tag
      }
    },
    changeLanguage(l) {
      this.$i18n.locale = l
      this.$route.params.lang = this.$i18n.locale
      this.$router.push({ name: this.$router.currentRoute.name, params: this.$route.params })
      window.location.reload()
    }
  }
}
</script>
<style>
.styling-cursor {
  cursor: pointer !important;
}

.btn-lang {
  border: 2px solid #272727;
  background-color: transparent !important;
  color: #000000 !important;
}

.btn-lang-selected {
  border: 2px solid #272727;
  color: #ffffff !important;
}
</style>
